import React, { useEffect, useState } from "react";
import heroImg from "../Assets/Tours List.webp";
import { RiFlightTakeoffLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ToursList = () => {
  document.title = 'Weekend Bhraman | Tour List';

  const [allToursList, setAllToursList] = useState([]);
  let apiUrl = process.env.REACT_APP_LIVE_URL;
  useEffect(() => {
    if (apiUrl) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiUrl}/package/getall`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setAllToursList(response?.data?.packageData);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Unable to get data");
    }
  }, [apiUrl]);
  let navigate = useNavigate();

  const goToTourDetails = (id) => {
    navigate(`/tour-details/${id}`);
  };
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="flex-grow relative">
          <div className="relative">
            <img className="w-full h-auto" src={heroImg} alt="Tourimg" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
              Our Tours
            </div>
          </div>
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="flex flex-wrap justify-center items-center gap-4 mt-4 mb-4">
          {!(allToursList.length > 0) ? (
            <>
              {[0, 1, 2, 3].map((index) => {
                return (
                  <div
                    key={index}
                    className="min-w-72 bg-gray-200 shadow-md rounded animate-pulse transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105"
                  >
                    <div className="w-full h-48 bg-gray-300 rounded-t-md"></div>
                    <div className="p-4">
                      <div className="mb-2">
                        <div className="text-lg font-semibold mb-2 bg-gray-300 h-5 w-2/3"></div>
                        <div className="flex justify-start items-center">
                          <div className="text-sm mb-2 text-gray-500 bg-gray-300 h-4 w-2/3"></div>
                        </div>
                      </div>
                      <div className="w-full h-px bg-gray-300 mb-2"></div>
                      <div className="flex justify-between items-center text-sm">
                        <div className="text-gray-500 bg-gray-300 h-4 w-1/3"></div>
                        <div className="text-[#ff783e] cursor-pointer font-semibold bg-gray-300 h-4 w-1/4"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 ">
              {allToursList?.map((data, index) => (
                <div
                  className="min-w-72 bg-[#F1F4F9] shadow-md rounded transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105"
                  key={index}
                >
                  <img
                    className="w-full h-48 object-cover rounded-t-md"
                    src={data?.image_url}
                    alt="tour-img"
                  />

                  <div className="p-4">
                    <div className="">
                      <div className="text-lg w-[250px] h-[50px] break-keep font-semibold mb-2 text-[#0C2D62]">
                        {data?.name}
                      </div>
                      <div className="flex justify-start items-center">
                        <RiFlightTakeoffLine
                          size={20}
                          color="#30aadd"
                          className="mt-[-8px] mr-1"
                        />
                        <div className="text-sm mb-2 text-[#8592A6]">
                          {data?.arrival_city}
                        </div>
                      </div>
                    </div>

                    <div className="w-full h-px bg-gray-300 mb-2"></div>

                    <div className="flex justify-between items-center text-sm">
                      <div className="text-[#0C2D62]">
                        {data?.tour_duration}
                      </div>

                      <div
                        onClick={() => goToTourDetails(data?._id)}
                        className="text-[#ff783e] cursor-pointer font-semibold hover:scale-110 hover:underline ease-in-out hover:duration-300 hover:text-[#d4531c] duration-300"
                      >
                        Explore Now
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default ToursList;
