import React from 'react';
import coveredCatsImg1 from '../Assets/div.tm-box-icon.svg';
import coveredCatsImg2 from '../Assets/div.tm-box-icon-1.svg';
import coveredCatsImg3 from '../Assets/div.tm-box-icon-2.svg';
import coveredCatsImg4 from '../Assets/div.tm-box-icon-3.svg';
import coveredCatsImg5 from '../Assets/div.tm-box-icon-4.svg';

const TourCategories = () => {
    const coveredCats = [
        { id: 1, image: coveredCatsImg1, name: 'Spiritual Tour' },
        { id: 2, image: coveredCatsImg2, name: 'World Wide Destinations' },
        { id: 3, image: coveredCatsImg3, name: 'Luxury Holiday Destinations in India' },
        { id: 4, image: coveredCatsImg4, name: 'Beach Side Destinations' },
        { id: 5, image: coveredCatsImg5, name: 'Mountain Tracking Destinations' },
    ];

  return (
    <React.Fragment>
                <div className="flex flex-col items-center justify-center mt-4 animate__animated animate__fadeIn">
                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-center mt-4 w-full'>
                        Sector we covered
                    </div>

                    <div className='text-[#051836] text-2xl font-bold text-center w-full mt-2'>
                        Tour Categories
                    </div>
                </div>

                <div className="mb-3 catagories-container flex overflow-x-auto md:justify-center items-center animate__animated animate__fadeIn pb-4">
                    {coveredCats.map((item) => (
                        <div key={item.id} className="bg-[#EBEBEB] h-44 w-44 p-4 m-4 flex flex-col items-center justify-center rounded-md transition-transform transform hover:scale-105">
                            <img src={item.image} alt='img' className="w-16 h-16 rounded-full mb-2" />
                            <div className="text-gray-800 text-sm font-semibold text-center">
                                {item.name}
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
  )
}

export default TourCategories
