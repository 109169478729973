import React from 'react';
import satisfiedClients from '../JsonPackages/customersRiview';
import demoProfile from "../Assets/pf.png"

const SatisfiedClient = () => {
    const StarRating = ({ initialRating }) => {
        return (
            <div>
                {[1, 2, 3, 4, 5].map((index) => (
                    <span
                        key={index}
                        className={`text-xl ${index <= initialRating ? 'text-yellow-500' : 'text-gray-300'
                            }`}
                    >
                        ★
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div>
            <React.Fragment>
                <div className="flex flex-col items-center justify-center">
                    <div className='text-[#051836] text-2xl font-bold text-center w-full mt-4'>
                        Client Satisfaction
                    </div>
                </div>

                <div className="catagories-container flex overflow-x-auto items-center mb-4">
                    {satisfiedClients?.client_riviews?.map((item, i) => (
                        <div key={i} className="bg-[#EBEBEB] min-h-72 max-h-72 min-w-52 max-w-52 p-4 m-4 flex flex-col items-center justify-center rounded-md">
                            <img src={
                                item.img_url !== ""
                                    ?
                                    item.img_url
                                    :
                                    demoProfile
                            }
                                alt='img'
                                className="min-w-16 min-h-16 max-w-16 max-h-16 rounded-full mb-2" />

                            <div className="text-[#0C2D62] text-sm font-bold text-center tracking-wide">
                                {item?.c_name}
                            </div>

                            <div className="text-[#0C2D62] text-xs text-center tracking-wide">
                                {item?.c_email}
                            </div>

                            <StarRating initialRating={item?.start_ratings} />

                            <div className="text-[#8592A6] text-xs text-center tracking-wide">
                                {
                                    item?.tour_experience.length < 210
                                        ?
                                        item?.tour_experience
                                        :
                                        item?.tour_experience?.slice(0, 210) + '...'
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        </div>
    )
}

export default SatisfiedClient
