/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import "./HomeContent.css";
import Grp1 from "../Assets/Grp-1.png";
import { RiFlightTakeoffLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import SatisfiedClient from "../Components/SatisfiedClient";
import ExperienceCard from "../Components/ExperienceCard";
import WhyChooseUsCard from "../Components/WhyChooseUsCard";
import InternationalToursPackages from "../Components/InternationalToursPackages";
import HolidayCategories from "../Components/HolidayCategories";
import TourCategories from "../Components/TourCategories";
import Carousel from "../Components/Carousel";

const HomeContent = () => {
  document.title = "Weekend Bhraman Tour Planner";
  let navigate = useNavigate();
  const [allToursList, setAllToursList] = useState([]);
  let apiUrl = process.env.REACT_APP_LIVE_URL;

  useEffect(() => {
    localStorage.removeItem("packageData");
    localStorage.removeItem("userData");
    if (apiUrl) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiUrl}/package/getall`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(
            "response?.data?.packageData",
            response?.data?.packageData
          );
          setAllToursList(response?.data?.packageData);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Unable to get data");
    }
  }, [apiUrl]);

  const goToTourDetails = (id) => {
    navigate(`/tour-details/${id}`);
  };

  return (
    <React.Fragment>
      <Carousel />

      <React.Fragment>
        <div className="flex flex-col items-center justify-center mt-6">
          <div
            style={{ fontFamily: "cursive" }}
            className="text-[#ff783e] text-center w-full"
          >
            Sector we covered
          </div>

          <div className="text-[#051836] text-2xl font-bold text-center w-full mt-2">
            Exclusive Holiday Tours
          </div>
        </div>

        <div className="flex flex-wrap gap-4 justify-center items-center mt-4">
          {!(allToursList.length > 0) ? (
            <>
              {[0, 1].map((index) => {
                return (
                  <div
                    key={index}
                    className="min-w-72 bg-gray-200 shadow-md rounded animate-pulse transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105"
                  >
                    <div className="w-full h-48 bg-gray-300 rounded-t-md"></div>
                    <div className="p-4">
                      <div className="mb-2">
                        <div className="text-lg font-semibold mb-2 bg-gray-300 h-5 w-2/3"></div>
                        <div className="flex justify-start items-center">
                          <div className="text-sm mb-2 text-gray-500 bg-gray-300 h-4 w-2/3"></div>
                        </div>
                      </div>
                      <div className="w-full h-px bg-gray-300 mb-2"></div>
                      <div className="flex justify-between items-center text-sm">
                        <div className="text-gray-500 bg-gray-300 h-4 w-1/3"></div>
                        <div className="text-[#ff783e] cursor-pointer font-semibold bg-gray-300 h-4 w-1/4"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 ">
              {allToursList?.map((data, index) => (
                <div
                  className="min-w-72 bg-[#F1F4F9] shadow-md rounded transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105"
                  key={index}
                >
                  <img
                    className="w-full h-48 object-cover rounded-t-md"
                    src={data?.image_url}
                    alt="tour-img"
                  />

                  <div className="p-4">
                    <div className="">
                      <div className="text-lg w-[250px] h-[50px] break-keep font-semibold mb-2 text-[#0C2D62]">
                        {data?.name}
                      </div>
                      <div className="flex justify-start items-center">
                        <RiFlightTakeoffLine
                          size={20}
                          color="#30aadd"
                          className="mt-[-8px] mr-1"
                        />
                        <div className="text-sm mb-2 text-[#8592A6]">
                          {data?.arrival_city}
                        </div>
                      </div>
                    </div>

                    <div className="w-full h-px bg-gray-300 mb-2"></div>

                    <div className="flex justify-between items-center text-sm">
                      <div className="text-[#0C2D62]">
                        {data?.tour_duration}
                      </div>

                      <div
                        onClick={() => goToTourDetails(data?._id)}
                        className="text-[#ff783e] cursor-pointer font-semibold hover:scale-110 hover:underline ease-in-out hover:duration-300 hover:text-[#d4531c] duration-300"
                      >
                        Explore Now
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </React.Fragment>

      <TourCategories />
      <ExperienceCard />
      <HolidayCategories />

      <InternationalToursPackages />
      <WhyChooseUsCard />
      <SatisfiedClient />
    </React.Fragment>
  );
};

export default HomeContent;
