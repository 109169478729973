import React from 'react';
import heroImg from "../Assets/About us.webp";
import founderPic from "../Assets/FoundersImg.png"
import OfcImg from "../Assets/OfcImg.png"
import { FaPersonCircleQuestion } from "react-icons/fa6";

const AboutUs = () => {
    document.title = 'Weekend Bhraman | About Us';

    return (
        <React.Fragment>
            <React.Fragment>
                <div className="flex-grow relative">
                    <div className="relative">
                        <img className="w-full h-auto" src={heroImg} alt="Tourimg" />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
                            About Us
                        </div>
                    </div>
                </div>
            </React.Fragment>

            <React.Fragment>
                <div className="container mx-auto p-8">
                    <div className="">
                        <div className='flex items-center'>
                            <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mb-4'>
                                Who are we..
                            </div>
                            <FaPersonCircleQuestion size={50} className='mt-[-2.3rem] mr-2' />
                        </div>

                        <p className="text-[#051836] leading-relaxed text-justify">
                            W33KEND BHRAMAN TOUR PLANNER offers a variety of Private Tours ideal for families and independent travelers seeking flexibility route, we offer the strongest destination knowledge and best value quality tours in Uttarakhand Himachal or others. Travel your next vacation with us and enjoy our amazing experiences in the “Land of God”! With our huge knowledge in the field, we are in a position to offer you an in depth collection of exciting journeys, which are not available elsewhere. We reveal the many hidden secrets and unexplored havens in this colorful and enticing land, along with some more familiar and well known sights. India has a vast variety of glorious palace hotels, small family run forts and people with an overall desire to please and be hospitable. Peaceful stay and a W33KEND  TOUR PLANNER helps you to make your weekend memorable in UTTRAKHAND without any hurdles such as Hotel Booking, Travelling, Fooding, Lodging etc… In that case, W33KEND BHRAMAN TOUR PLANNER will solve all your queries regarding your all other things. Book your precious vacations with us and give us the opportunity to serve you.
                        </p>
                    </div>

                    <div className="mt-8">
                        <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start w-full mb-4'>
                            Our Commitments
                        </div>

                        <p className="text-[#051836] leading-relaxed text-justify">
                            <div>
                                We don't fight price, we only fight quality, service, and after-sales.
                            </div>
                            <div>
                                2. We can't guarantee the lowest price, but we can assure you the highest quality, accommodation, transportation, and all kinds of services during the journey.
                            </div>
                            <div>
                                3. We only do repeat business, not one-time business. Free shopping.
                            </div>
                            <div>
                                4. Trust us! Choose us! We will not disappoint your trust and expectations.
                            </div>
                            <div>
                                5. The same price compares quality, the same quality compares price, the same price compares service, and the same service compares after-sales.
                            </div>
                        </p>
                    </div>

                    <div className="mt-8 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
                        <div className="md:w-1/2">
                            <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start w-full mb-4'>
                                Our Vission
                            </div>

                            <p className="text-[#051836] leading-relaxed text-justify">
                                Our vision is to be the innovative leader and creative in the concept of travel. We want to make our company identity in the markets within the best tourism companies in Uttarakhand, Himachal and other tourist destinations. We want to be recognized for our professionalism to provide high quality services.
                            </p>
                        </div>

                        <div className="md:w-1/2">
                            <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start w-full mb-4'>
                                Our Mission
                            </div>

                            <p className="text-[#051836] leading-relaxed text-justify">
                                Our mission is to perform and give an excellent quality service to our customers by providing them an unforgettable traveling with full enjoyment and also guaranteed services that exceeds your expectations. Our aim is to earn reputation as “YOUR PREFFERED TRAVEL AGENCY”. All we need is our customer satisfaction, and our very main aim is to save your time and money as well as provide you the best value for your trip.
                            </p>
                        </div>
                    </div>

                    <div className="bg-[#cae9f7] w-full border-2 border-[#30aadd] p-4 rounded-lg flex flex-col md:flex-row items-center mt-8">
                        {/* First Column (Image) */}
                        <div className="w-full flex justify-center md:w-1/2">
                            <img
                                src={founderPic}
                                className=" max-h-80 max-w-80 rounded-md"
                                alt="founder-img" />
                        </div>

                        {/* Second Column (Information) */}
                        <div className="w-full md:w-1/2 text-start md:text-left">
                            <h2 className="text-2xl font-bold mb-4 tracking-wide text-[#ff783e]" style={{ fontFamily: 'cursive' }}>Founder & Chairman</h2>

                            <div className='text-start space-y-2'>
                                <p className='text-[#051836] tracking-wide font-bold text-lg'>
                                    Diwakar Singh
                                </p>
                                <p className='text-[#051836] tracking-wide text-md'>
                                    chairman.weekendbhraman@gmail.com
                                </p>
                                <p className='text-[#051836] tracking-wide text-md'>
                                    +91-9540512487 / +91-8076663076
                                </p>
                                <p className='text-[#051836] tracking-wide text-md'>
                                    Working with our professional experience of 10+ years expierence in Hospitality and tourism sector, He exemplifies under weaving commitment to ethics decipline.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="bg-[#cae9f7] w-full border-2 border-[#30aadd] p-4 rounded-lg flex flex-col md:flex-row items-center mt-8">
                        <div className="w-full flex justify-center md:w-1/2">
                            <img
                                src={OfcImg}
                                className=" max-h-80 max-w-80 rounded-md"
                                alt="founder-img" />
                        </div>

                        <div className="w-full md:w-1/2 text-start md:text-left">
                            <h2 className="text-2xl font-bold mb-4 tracking-wide text-[#ff783e]" style={{ fontFamily: 'cursive' }}>
                                Office Details
                            </h2>

                            <div className='text-start space-y-2'>
                                <p className='text-[#051836] tracking-wide font-bold text-lg'>
                                    Head Office
                                </p>
                                <p className='text-[#051836] tracking-wide text-md'>
                                    Info@weekendbhraman.com
                                </p>
                                <p className='text-[#051836] tracking-wide text-md'>
                                    +91-8178958591, +91-9310366002
                                </p>
                                <p className='text-[#051836] tracking-wide text-md'>
                                    A-37 Sachdeva Complex, Madhu Vihar I.P Ext. New Delhi-92
                                </p>
                            </div>

                            <div className='text-start space-y-2 mt-4'>
                                <p className='text-[#051836] tracking-wide font-bold text-lg'>
                                    Branch Office
                                </p>

                                <p className='text-[#051836] tracking-wide text-md'>
                                    Haridwar: Dudhadhari Chowk, Near Rajput, Dharmsala, Bhupatwala, Haridwar-249410
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment >
    )
}

export default AboutUs
