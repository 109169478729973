import React from 'react';
import heroImg from '../Assets/Gallery.webp';
import images from "../JsonPackages/galleryImages";

const Gallery = () => {
    document.title = 'Weekend Bhraman | Gallery';

    return (
        <React.Fragment>
            <React.Fragment>
                <div className="flex-grow relative">
                    <div className="relative">
                        <img
                            className="w-full h-auto"
                            src={heroImg}
                            alt="Tourimg"
                        />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
                            Our Gallery
                        </div>
                    </div>
                </div>
            </React.Fragment>

            <React.Fragment>
                <div className="container mx-auto px-4">
                    <section className="py-8 px-4">
                        <div className="flex flex-wrap">
                            {
                                images?.img_row_1.map((img, k) => {
                                    return (
                                        <div key={k} className="md:w-1/2 px-4 mb-8 md:mb-0">
                                            <img className="rounded shadow-md" src={img} alt="1st-img-row" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>

                    <section className=" px-4">
                        <div className="flex flex-wrap">
                            {
                                images?.img_row_2.map((img, l) => {
                                    return (
                                        <div className="md:w-1/3 px-4 mb-8" key={l}>
                                            <img className="rounded shadow-md" src={img} alt="2nd-img-row" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>

                    <section className="pb-8 px-4">
                        <div className="flex flex-wrap">
                            {
                                images?.img_row_3.map((img, m) => {
                                    return (
                                        <div className="md:w-1/4 px-4 mb-8" key={m}>
                                            <img className="rounded shadow-md" src={img} alt="3rd-img-row" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}

export default Gallery
