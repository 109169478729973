import React, { useEffect, useState, useCallback } from "react";
import logoImage from "../Assets/logo_wb.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./LowerHeader.css";
import { GiHamburgerMenu } from "react-icons/gi";

const MiddleHeader = () => {
  let navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [hamburger, setHamburger] = useState(false)
  const location = useLocation();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const sidebar = document.querySelector('#sidebar');
      if (sidebar && !sidebar.contains(event.target)) {
        setHamburger(false);
      }
    };

    if (hamburger) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [hamburger]);

  const goToTours = useCallback(() => {
    navigate("/tour-list");
  }, [navigate]);

  const goToGallery = useCallback(() => {
    navigate("/gallery");
  }, [navigate]);

  const goToHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const goToAboutUs = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const goToContactUs = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const goToBus = useCallback(() => {
    navigate("/bus");
  }, [navigate]);

  const goToFlight = useCallback(() => {
    navigate("/flight");
  }, [navigate]);

  const goToFeedback = useCallback(() => {
    navigate("/feedback");
  }, [navigate]);

  useEffect(() => {
    if (selectedValue === "home") {
      goToHome();
    } else if (selectedValue === "tours") {
      goToTours();
    } else if (selectedValue === "gallery") {
      goToGallery();
    } else if (selectedValue === "aboutUs") {
      goToAboutUs();
    } else if (selectedValue === "contactUs") {
      goToContactUs();
    } else if (selectedValue === "flights") {
      goToFlight();
    } else if (selectedValue === "bus") {
      goToBus();
    } else if (selectedValue === "feedback") {
      goToFeedback();
    } else {
      return;
    }
  }, [
    goToAboutUs,
    goToBus,
    goToContactUs,
    goToFlight,
    goToGallery,
    goToHome,
    goToTours,
    goToFeedback,
    selectedValue
  ]);

  return (
    <React.Fragment>
      <nav className="bg-white px-4 py-0 md:py-3">
        <div className="container mx-auto flex flex-col md:flex-row items-center md:justify-between">

          <input
            className="h-[0vh] w-[0vh] hidden bg-transparent border-0 ring-0 outline-none"
            autofocus
          />

          <div>
            <img
              src={logoImage}
              alt="Logo"
              className="w-16 h-16 object-contain md:mr-auto xl:flex lg:flex md:hidden flex "
            />
          </div>

          <div className="hidden md:flex items-center justify-center">
            <div
              onClick={goToHome}
              className={`px-4 text-[#051836] text-lg tracking-wide font-semibold cursor-pointer
  ${location.pathname === "/" ? "text-orange-500" : ""}
  hover:text-[#ff783e] hover:scale-110 duration-300 ease-in-out hover:duration-300`}
            >
              Home
            </div>

            <div
              onClick={goToTours}
              className={`px-4 text-[#051836] text-lg tracking-wide font-semibold cursor-pointer
  ${location.pathname === "/tour-list" ? "text-orange-500" : ""}
  hover:text-[#ff783e] hover:scale-110 duration-300 ease-in-out hover:duration-300`}
            >
              Tours
            </div>

            <div
              onClick={goToFlight}
              className={`px-4 text-[#051836] text-lg tracking-wide font-semibold cursor-pointer
  ${location.pathname === "/flight" ? "text-orange-500" : ""}
  hover:text-[#ff783e] hover:scale-110 duration-300 ease-in-out hover:duration-300`}
            >
              Flight
            </div>

            <div
              onClick={goToBus}
              className={`px-4 text-[#051836] text-lg tracking-wide font-semibold cursor-pointer
  ${location.pathname === "/bus" ? "text-orange-500" : ""}
  hover:text-[#ff783e] hover:scale-110 duration-300 ease-in-out hover:duration-300`}
            >
              Bus
            </div>

            <div
              onClick={goToGallery}
              className={`px-4 text-[#051836] text-lg tracking-wide font-semibold cursor-pointer
  ${location.pathname === "/gallery" ? "text-orange-500" : ""}
  hover:text-[#ff783e] hover:scale-110 duration-300 ease-in-out hover:duration-300`}
            >
              Gallery
            </div>

            <div
              onClick={goToAboutUs}
              className={`px-4 text-[#051836] text-lg tracking-wide font-semibold cursor-pointer
  ${location.pathname === "/about-us" ? "text-orange-500" : ""}
  hover:text-[#ff783e] hover:scale-110 duration-300 ease-in-out hover:duration-300`}
            >
              AboutUs
            </div>

            <div
              onClick={goToContactUs}
              className={`px-4 text-[#051836] text-lg tracking-wide font-semibold cursor-pointer
  ${location.pathname === "/contact-us" ? "text-orange-500" : ""}
  hover:text-[#ff783e] hover:scale-110 duration-300 ease-in-out hover:duration-300`}
            >
              ContactUs
            </div>

            <div
              onClick={goToFeedback}
              className={`px-4 text-[#051836] text-lg tracking-wide font-semibold cursor-pointer
  ${location.pathname === "/feedback" ? "text-orange-500" : ""}
  hover:text-[#ff783e] hover:scale-110 duration-300 ease-in-out hover:duration-300`}
            >
              Feedback
            </div>
          </div>

          <div id="sidebar" className=" md:hidden flex justify-between items-center w-full md:w-auto">
            <button onClick={() => setHamburger(!hamburger)}>
              <GiHamburgerMenu size={24} />
            </button>
            <div className={`sidebar transition-transform transform ${hamburger ? "translate-x-0" : "-translate-x-full"} fixed top-0 left-0 z-10 bg-white p-6 w-[60%] h-[100vh]`}>

              <div >
                <p
                  className={`rounded-xl p-2 text-center cursor-pointer tracking-wider font-semibold
      ${location.pathname === "/" ? "bg-[#ff783e] text-white" : "bg-gray-200"}
    `}
                  onClick={() => {
                    setHamburger(false);
                    navigate('/');
                  }}
                >
                  Home
                </p>
                <p
                  className={`rounded-xl p-2 text-center mt-2 cursor-pointer tracking-wider font-semibold
      ${location.pathname === "/tour-list" ? "bg-[#ff783e] text-white" : "bg-gray-200"}
    `}
                  onClick={() => {
                    setHamburger(false);
                    navigate('/tour-list');
                  }}
                >
                  Tours
                </p>
                <p
                  className={`rounded-xl p-2 text-center mt-2 cursor-pointer tracking-wider font-semibold
      ${location.pathname === "/flight" ? "bg-[#ff783e] text-white" : "bg-gray-200"}
    `}
                  onClick={() => {
                    setHamburger(false);
                    navigate('/flight');
                  }}
                >
                  Flight
                </p>
                <p
                  className={`rounded-xl p-2 text-center mt-2 cursor-pointer tracking-wider font-semibold
      ${location.pathname === "/bus" ? "bg-[#ff783e] text-white" : "bg-gray-200"}
    `}
                  onClick={() => {
                    setHamburger(false);
                    navigate('/bus');
                  }}
                >
                  Bus
                </p>
                <p
                  className={`rounded-xl p-2 text-center mt-2 cursor-pointer tracking-wider font-semibold
      ${location.pathname === "/gallery" ? "bg-[#ff783e] text-white" : "bg-gray-200"}
    `}
                  onClick={() => {
                    setHamburger(false);
                    navigate('/gallery');
                  }}
                >
                  Gallery
                </p>
                <p
                  className={`rounded-xl p-2 text-center mt-2 cursor-pointer tracking-wider font-semibold
      ${location.pathname === "/about-us" ? "bg-[#ff783e] text-white" : "bg-gray-200"}
    `}
                  onClick={() => {
                    setHamburger(false);
                    navigate('/about-us');
                  }}
                >
                  AboutUs
                </p>

                <p
                  className={`rounded-xl p-2 text-center mt-2 cursor-pointer tracking-wider font-semibold
      ${location.pathname === "/contact-us" ? "bg-[#ff783e] text-white" : "bg-gray-200"}
    `}
                  onClick={() => {
                    setHamburger(false);
                    navigate('/contact-us');
                  }}
                >
                  ContactUs
                </p>

                <p
                  className={`rounded-xl p-2 text-center mt-2 cursor-pointer tracking-wider font-semibold
      ${location.pathname === "/feedback" ? "bg-[#ff783e] text-white" : "bg-gray-200"}
    `}
                  onClick={() => {
                    setHamburger(false);
                    navigate('/feedback');
                  }}
                >
                  Feedback
                </p>
              </div>

            </div>

            <button
              onClick={goToTours}
              className="mb-2 md:mb-0 md:w-auto xl:w-[40%] lg:w-[40%] w-[50%] px-6 py-1 text-lg text-white bg-[#ff783e] hover:bg-[#d4531c] transition ease-in-out hover:duration-300 rounded-full shadow-lg shadow-[#ffdcc7]"
            >
              Book now
            </button>
          </div>

          <button
            onClick={goToTours}
            className="xl:flex lg:flex hidden mb-2 md:mb-0 md:w-auto w-[40%] px-6 py-1 text-lg text-white bg-[#ff783e] hover:bg-[#d4531c] transition ease-in-out hover:duration-300 rounded-full shadow-lg shadow-[#ffdcc7]"
          >
            Book now
          </button>

          <button
            onClick={goToTours}
            className="md:flex xl:hidden lg:hidden hidden mb-2 md:mb-0 md:w-auto w-[40%] px-6 py-1 text-lg text-white bg-[#ff783e] hover:bg-[#d4531c] transition ease-in-out hover:duration-300 rounded-full shadow-lg shadow-[#ffdcc7]"
          >
            Tour
          </button>

        </div>
      </nav>
    </React.Fragment>
  );
};

export default MiddleHeader;
