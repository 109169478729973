import React, { useState } from "react";
import { Loading } from "./Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const Razorpay = ({ userData, amount, packageData }) => {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState("");
  const [payment, setPayment] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_LIVE_URL;
  const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;

  const sendMail = async () => {
    let data = JSON.stringify([
      {
        email: userData?.email || "",
        userName: userData?.name,
        send_amount: amount,
      },
      {
        email: "tripathysatyaranjan3@gmail.com",
        userName: "admin",
        received_amount: amount,
      },
    ]);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiUrl}/email/sendPaymentEmails`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        // console.log(JSON.stringify(response.data));
        if (response?.data) {
          navigate("/paymentSuccess");
        } else {
          navigate("/paymentFailure");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveUserData = async () => {
    let data = JSON.stringify({
      userName: userData?.name || "",
      firstName: "",
      lastName: "",
      email: userData?.email || "",
      contact: userData?.name || "",
      country_code: "IN",
      gender: "",
      dob: "",
      address: "",
      state: "",
      selectPackage: packageData?.package?._id || "",
      packageCategory_id: packageData?.packageCategory?._id || "",
      adults: "",
      city: userData?.expected_deparature_city || "",
      comment: "Order payment",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiUrl}/user/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        sendMail();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const initiatePayment = async () => {
    let localPackageData =
      JSON.parse(localStorage.getItem("packageData")) || {};

    if (apiUrl && localPackageData) {
      console.log("localPackageData", localPackageData?.packageCategory?.price);
      let data = {
        amount: amount,
        currency: "INR",
        name: localPackageData?.packageCategory?.packageName,
        description: localPackageData?.packageCategory?.packageName,
        image: "https://s3.amazonaws.com/rzp-mobile/images/rzp.jpg",
        profile_name: userData?.name,
        email: userData?.email,
        product: localPackageData?.packageCategory?.packageName,
        number: userData?.number,
        address: "",
        callback_url: "http://localhost:5000/api/payment-callback",
        cancel: "http://localhost:5000/api/payment-cancel",
      };
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${apiUrl}/razorpay/orders`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await axios.request(config);
        return response?.data?.order;
      } catch (error) {
        console.error("Error during payment initiation:", error);
        return false;
      }
    }
  };

  const openRazorpayCheckout = async () => {
    setLoadingData("opening Razorpay");
    setLoading(true);
    const apiKey = RAZORPAY_KEY_ID;

    const order_id = await initiatePayment();
    let localPackageData =
      JSON.parse(localStorage.getItem("packageData")) || {};
    if (order_id) {
      const options = {
        key: apiKey,
        amount: amount,
        currency: "INR",
        name: userData?.name,
        description: localPackageData?.packageCategory?.packageName,
        image: "",
        order_id: order_id,
        prefill: {
          name: userData?.name,
          email: userData?.email,
          contact: userData?.number,
        },
        handler: async (response) => {
          const config = {
            method: "post",
            url: `${apiUrl}/razorpay/payment-callback`,
            headers: {},
            data: response,
          };

          try {
            const paymentResponse = await axios.request(config);

            if (paymentResponse?.data?.payment_status === true) {
              setPayment(paymentResponse?.data);
              setLoadingData("Sending Email");
              setLoading(true);
              saveUserData();
            }
          } catch (error) {
            console.error(error);
          }
        },
        modal: {
          ondismiss: () => {
            if (window.confirm("Are you sure, you want to close the form?")) {
              console.log("Checkout form closed by the user");
            } else {
              console.log("Complete the Payment");
            }
          },
        },
      };
      setLoading(false);
      const rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      alert("Unable to create order");
    }
  };

  return (
    <>
      {loading && <Loading data={loadingData} />}

      <div>
        {payment && (
          <div>
            <h2>Payment Successful!</h2>
            <p>Payment ID: {payment.razorpay_payment_id}</p>
          </div>
        )}
      </div>

      <button
        onClick={openRazorpayCheckout}
        disabled={loading}
        className="tracking-wide font-semibold mt-4 w-full px-6 py-2 text-lg text-white bg-[#ff783e] hover:bg-[#d4531c] transition ease-in-out hover:duration-300 rounded-full shadow-lg"
      >
        {loading ? "Please wait..." : "Pay & Book Now"}
      </button>
    </>
  );
};
